import React, { useContext, useEffect, useState } from "react";
import TextInput from "./TextInput";
import DropDownList from "./DropDownList";
import axiosInstance from "../helper/axios";
import Trans from "../helper/Trans";
import DropDownGroupedList from "./DropDownGroupedList";
import NumberInput from "./NumberInput";
import DataLoading from "./DataLoading";
import { notify } from "../helper/Helper";
import { UserContext } from "./UserContext";
import { useNavigate } from "react-router-dom";

const attApi = axiosInstance;
function AcademicForm(props) {
  const { siteInfo, getFillStatus, applicantStatus } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [useBoards, setUseBoards] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const navigate = useNavigate();
  const [img, setImg] = useState(
    formData.document?(
    formData.document === "" || formData.document === undefined
      ? ""
      : process.env.REACT_APP_BACKEND_URL2 + props.formData.document):""
  );

  const [errors, setErrors] = useState({});

  function moveNext() {
    let to = "/choose";
    if (applicantStatus && applicantStatus.moreInfo === true) {
      to = "/moreinfo";
    }
    navigate(to);
  }
  useEffect(() => {
    if (props.studyLevelId && props.studyLevelId !== "") {
      attApi
        .get("get-academics?studyLevel=" + props.studyLevelId)
        .then((response) => {
          setFormData(response.data.data);
          setIsNew(response.data.isNew);
        });
    }
  }, [props.studyLevelId]);

  const gradeList = {
    "A+": "A+",
    A: "A",
    "A-": "A-",
    "B+": "B+",
    B: "B",
    "B-": "B-",
    "C+": "C+",
    C: "C",
    "C-": "C-",
    "D+": "D+",
    D: "D",
    "D-": "D-",
    F: "F",
    NA: "N/A",
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
    if (name === "major") {
      let useb = props.useBoards[value] ? props.useBoards[value] : false;
      setUseBoards(useb);
    }
  };

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      const { name } = e.target;
      setImg((window.URL || window.webkitURL).createObjectURL(img));
      setFormData({
        ...formData,
        [name]: img,
      });
    }
  };

  function saveData(e) {
    e.preventDefault();
    const newErrors = {};

    props.reqCols.forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = `${props.formMeta[key]} cannot be blank.`;
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      notify("Error Validating the Form", "error");
      return;
    }

    setLoading(true);
    var formData2 = new FormData();
    Object.keys(formData).forEach((key) => {
      formData2.append(`ApplicantAcademic[${key}]`, formData[key]);
    });

    let url = "add-academic?studyLevel=" + props.studyLevelId;

    attApi
      .post(url, formData2, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === false) {
            Object.keys(res.data.errors).forEach((errorKey) => {
              newErrors[errorKey] = res.data.errors[errorKey];
            });
            setErrors(newErrors);
            notify("Error Validating the Form", "error");
          } else {
            notify(res.data.message, "success");
            getFillStatus();
          }
        } else {
          notify(res.data.message, "error");
        }
      })
      .catch((err) => {
        notify("Error Validating the Form", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="card mb-4">
      <div className="card-body">
        <>
          <h4>{props.head}</h4>
          <p className="text-14">
            <b>{props.instructions}</b>
          </p>
          <div className="row">
            <div className="col-md-6">
              <DropDownGroupedList
                name="major"
                label={props.formMeta.study_level_id}
                value={formData.major}
                handleInputChange={handleInputChange}
                reqCols={props.reqCols}
                prompt={"-Select-"}
                listData={props.studyLevel}
                errors={errors}
              />
            </div>

            {useBoards !== "0" ? (
              <div className="col-md-6">
                <DropDownList
                  name="board"
                  label={props.formMeta.board}
                  value={formData.board}
                  handleInputChange={handleInputChange}
                  reqCols={props.reqCols}
                  prompt={"-Select-"}
                  listData={props.boards}
                  errors={errors}
                />
              </div>
            ) : (
              <></>
            )}
            <div className="col-md-6">
              <TextInput
                name="institute"
                label={props.formMeta.institute}
                value={formData.institute}
                handleInputChange={handleInputChange}
                reqCols={props.reqCols}
                length={150}
                errors={errors}
              />
            </div>
            <div className="col-md-3">
              <TextInput
                name="reg_no"
                label={props.formMeta.reg_no}
                value={formData.reg_no}
                handleInputChange={handleInputChange}
                reqCols={props.reqCols}
                length={50}
                errors={errors}
              />
            </div>
            <div className="col-md-3">
              <NumberInput
                name="total"
                label={props.formMeta.total}
                value={formData.total}
                handleInputChange={handleInputChange}
                reqCols={props.reqCols}
                min={0}
                max={4000}
                errors={errors}
              />
            </div>
            <div className="col-md-3">
              <NumberInput
                name="obtained"
                label={props.formMeta.obtained}
                value={formData.obtained}
                handleInputChange={handleInputChange}
                reqCols={props.reqCols}
                min={0}
                max={4000}
                errors={errors}
              />
            </div>
            <div className="col-md-3">
              <DropDownList
                name="passed"
                label={props.formMeta.passed}
                value={formData.passed}
                handleInputChange={handleInputChange}
                reqCols={props.reqCols}
                prompt={"-Select-"}
                listData={props.YearsList}
                errors={errors}
              />
            </div>
            <div className="col-md-3">
              <DropDownList
                name="grade"
                label={props.formMeta.grade}
                value={formData.grade}
                handleInputChange={handleInputChange}
                reqCols={props.reqCols}
                prompt={"-Select-"}
                listData={gradeList}
                errors={errors}
              />
            </div>

            <div className="col-md-3">
              <DropDownList
                name="supplementary"
                label={props.formMeta.supplementary}
                value={formData.supplementary}
                handleInputChange={handleInputChange}
                reqCols={props.reqCols}
                listData={props.YesNoList}
                errors={errors}
              />
            </div>
            {props.showAwait ? (
              <div className="col-md-3">
                <DropDownList
                  name="result_awaiting"
                  label={props.formMeta.result_awaiting}
                  value={formData.result_awaiting}
                  handleInputChange={handleInputChange}
                  reqCols={props.reqCols}
                  listData={props.YesNoList}
                  errors={errors}
                />
              </div>
            ) : (
              <></>
            )}
            {siteInfo && siteInfo.take_academic_document === "1" ? (
              <>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label" htmlFor="document">
                      {props.formMeta.document}
                    </label>
                    <input
                      type="file"
                      id="document"
                      name="document"
                      onChange={onImageChange}
                      className={` ${errors["document"] ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors["document"]}</div>
                  </div>
                </div>
                <div className="col-md-4">
                  {img === "" ? (
                    <></>
                  ) : (
                    <img
                      src={img}
                      height="150px"
                      width="150px"
                      className={`thumbnail mb-3 `}
                      alt=""
                    />
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </>

        <div className="form-group mt-3">
          {loading ? (
            <DataLoading />
          ) : (
            <>
              {props.allowSave && Object.keys(props.studyLevel).length > 0 ? (
                <button
                  type="submit"
                  className="btn btn-primary"
                  name="save-button"
                  onClick={saveData}
                >
                  {isNew ? Trans("Save") : Trans("Update")}
                </button>
              ) : (
                <></>
              )}

              {applicantStatus &&
              applicantStatus.academicInfo === true &&
              applicantStatus.applied === false ? (
                <button
                  className="btn btn-outline-secondary m-2"
                  onClick={moveNext}
                >
                  {Trans("Next")}
                </button>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AcademicForm;
