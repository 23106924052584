import React, { useEffect, useState } from "react";
import axiosInstance from "../helper/axios";
import DropDownList from "./DropDownList";
import DateInput from "./DateInput";
import TextInput from "./TextInput";
import DataLoading from "./DataLoading";
import Trans from "../helper/Trans";
import { notify } from "../helper/Helper";

const attApi = axiosInstance;
function UploadBC2(props) {
  const dataId = props.data.alloted_degree_id;
  const [loading, setLoading] = useState(false);
  const [banks, setBanks] = useState(false);
  const [eBCMeta, setEBCMeta] = useState({});
  const [BCData, setBCData] = useState({});
  const [eBCReqCols, setEBCReqCols] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setLoading(true);
    attApi
      .get("get-admission-banks?id=" + dataId+"&download=false")
      .then((response) => {
        setBanks(response.data.banks);
        setEBCMeta(response.data.meta);
        setBCData(response.data.model);
        setEBCReqCols(response.data.required);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataId]);

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      const { name } = e.target;
      setBCData({
        ...BCData,
        [name]: img,
      });
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBCData({
      ...BCData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const uploadBankChallan2 = (e) => {
    e.preventDefault();
    const newErrors = {};

    eBCReqCols.forEach((key) => {
      if (!BCData[key]) {
        newErrors[key] = `${eBCMeta[key]} cannot be blank.`;
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      notify("Error Validating the Form", "error");
      return;
    }
    var formData2 = new FormData();

    Object.keys(BCData).forEach((key) => {
      formData2.append(`UpdateBC2[${key}]`, BCData[key]);
    });
    setLoading(true);
    attApi
      .post("/upload-admission-challan?id=" + dataId, formData2, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === false) {
            Object.keys(res.data.errors).forEach((errorKey) => {
              newErrors[errorKey] = res.data.errors[errorKey];
            });
            setErrors(newErrors);
            notify("Error Validating the Form", "error");
          } else {
            notify(res.data.message, "success");
            props.refreshAppData();
            props.setOpenBCModal(false)
          }
        } else {
          notify(res.data.message, "error");
        }
      })
      .catch((err) => {
        notify("Error Validating the Form", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      {loading ? (
        <DataLoading />
      ) : (
        <div className="col-md-12">
          <div className="card mt-3">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <DropDownList
                    name="bc_bank"
                    label={eBCMeta.bc_bank}
                    value={BCData.bc_bank}
                    handleInputChange={handleInputChange}
                    prompt={"-Select-"}
                    reqCols={eBCReqCols}
                    listData={banks}
                    errors={errors}
                  />
                </div>
                <div className="col-md-4">
                  <DateInput
                    name="bc_date"
                    label={eBCMeta.bc_date}
                    value={BCData.bc_date}
                    length={10}
                    handleInputChange={handleInputChange}
                    reqCols={eBCReqCols}
                    errors={errors}
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    name="narration"
                    label={eBCMeta.narration}
                    value={BCData.narration}
                    length={500}
                    handleInputChange={handleInputChange}
                    reqCols={eBCReqCols}
                    errors={errors}
                  />
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label" htmlFor="bc_img">
                      {eBCMeta.bc_img}
                    </label>
                    <span
                      style={{
                        color: "red",
                        marginRight: 4,
                        marginLeft: 10,
                      }}
                    >
                      *
                    </span>
                    <input
                      type="file"
                      id="bc_img"
                      name="bc_img"
                      onChange={onImageChange}
                      className={` ${errors["bc_img"] ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors["bc_img"]}</div>
                  </div>
                </div>
                <div className="col-md-12">
                  <button
                    type="button"
                    className=" btn btn-primary mt-3 mr-2"
                    onClick={uploadBankChallan2}
                  >
                    {Trans("Upload")}
                  </button>
                  <button
                    type="button"
                    className=" btn btn-danger mt-3"
                    onClick={() => {
                      props.setOpenBCModal(false);
                    }}
                  >
                    {Trans("Close")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UploadBC2;
